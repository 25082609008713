import { Build, BuildStatus, isBuildRunning } from "@todesktop/shared";
import semver from "semver";
import { getPlatformBuildsNotCodeSigned } from "./common";
import {
  isBuildSupportsSmokeTests,
  isSmokeTestRunning,
  smokeTestHasError,
} from "./smokeTest";

export const MIN_RUNTIME_VERSION_FOR_DOWNGRADE = "1.4.1-1";

type EligibilityKey =
  | "releaseState"
  | "buildState"
  | "codeSignState"
  | "versionState"
  | "smokeTestState";

export enum EligibilityState {
  "eligible",
  "loading",
  "ineligible",
}

export type Eligibility = Record<EligibilityKey, EligibilityState>;

export const determineReleaseEligibility = (
  build: Build,
  releasedBuild?: Build
): {
  eligibility: Eligibility;
  isDowngrade?: boolean;
  isLoading?: boolean;
  isEqual?: boolean;
  isPreparable: boolean;
  isReleaseable: boolean;
  isLatestRelease: boolean;
} => {
  const loadingStateUnlessCancelled =
    build?.status === BuildStatus.cancelled
      ? EligibilityState.ineligible
      : EligibilityState.loading;

  const eligibility: Eligibility = {
    releaseState: loadingStateUnlessCancelled,
    buildState: loadingStateUnlessCancelled,
    codeSignState: loadingStateUnlessCancelled,
    versionState: loadingStateUnlessCancelled,
    smokeTestState: loadingStateUnlessCancelled,
  };

  if (!build) {
    return {
      eligibility,
      isLatestRelease: false,
      isReleaseable: false,
      isPreparable: false,
    };
  }

  // build state
  if (build.status === "succeeded") {
    eligibility.buildState = EligibilityState.eligible;
  } else if (!isBuildRunning(build)) {
    eligibility.buildState = EligibilityState.ineligible;
  }

  // release and version state
  let isDowngrade = false;
  if (isDowngradable(releasedBuild)) {
    Object.assign(eligibility, getDowngradeEligibility(build, releasedBuild));
    try {
      isDowngrade =
        build.appVersion &&
        semver.gt(releasedBuild?.appVersion || "0.0.0", build.appVersion);
    } catch (error) {
      console.error(
        "Error determining downgrade eligibility, probably a buggy semver",
        error
      );
    }
  } else {
    Object.assign(eligibility, getUpgradeEligibility(build, releasedBuild));
  }

  // code sign state (still loading if didCodeSign not set by desktopify)
  if (
    (build.mac?.shouldSkip || "didCodeSign" in build["mac"]) &&
    (build.windows?.shouldSkip || "didCodeSign" in build["windows"])
  ) {
    if (getPlatformBuildsNotCodeSigned(build).length === 0) {
      eligibility.codeSignState = EligibilityState.eligible;
    } else {
      eligibility.codeSignState = EligibilityState.ineligible;
    }
  }

  // smoke test state
  if (isBuildSupportsSmokeTests(build)) {
    if (!build.smokeTest) {
      eligibility.smokeTestState = EligibilityState.eligible;
    } else if (build.smokeTest.isCanceled) {
      eligibility.smokeTestState = EligibilityState.ineligible;
    } else if (isSmokeTestRunning(build)) {
      eligibility.smokeTestState = EligibilityState.loading;
    } else if (smokeTestHasError(build)) {
      eligibility.smokeTestState = EligibilityState.ineligible;
    } else {
      eligibility.smokeTestState = EligibilityState.eligible;
    }
  } else {
    eligibility.smokeTestState = EligibilityState.eligible;
  }

  const values = [
    eligibility.releaseState,
    eligibility.buildState,
    eligibility.codeSignState,
    eligibility.versionState,
    // eligibility.smokeTestState - smoke teststate doesn't affect release eligibility
  ];

  let isEqual = false;
  try {
    isEqual =
      build.appVersion &&
      semver.eq(build.appVersion, releasedBuild?.appVersion || "0.0.0");
  } catch (error) {
    isEqual = true;
    console.error(
      "Error determining downgrade eligibility, probably a buggy semver",
      error
    );
  }

  return {
    eligibility,
    isLatestRelease: build.id === releasedBuild?.id,
    isDowngrade,
    isPreparable:
      eligibility.buildState === EligibilityState.eligible &&
      eligibility.codeSignState === EligibilityState.eligible,
    isReleaseable: values.every((value) => value === EligibilityState.eligible),
    isLoading: values.some((value) => value === EligibilityState.loading),
    isEqual,
  };
};

function getDowngradeEligibility(build: Build, releasedBuild?: Build) {
  const eligibility: Partial<Eligibility> = {
    releaseState: EligibilityState.eligible,
  };

  // version state (still loading if appVersion not yet set by desktopify)
  if (build.appVersion) {
    if (releasedBuild.appVersion !== build.appVersion) {
      eligibility.versionState = EligibilityState.eligible;
    } else {
      eligibility.versionState = EligibilityState.ineligible;
    }
  }

  return eligibility;
}

function getUpgradeEligibility(
  build: Build,
  releasedBuild?: Build
): Partial<Eligibility> {
  const eligibility: Partial<Eligibility> = {};
  try {
    // release state (only an eligible release candidate if not already released)
    if (build.releasedAt) {
      eligibility.releaseState = EligibilityState.ineligible;
    } else {
      eligibility.releaseState = EligibilityState.eligible;
    }

    // version state (still loading if appVersion not yet set by desktopify)
    try {
      if (build.appVersion) {
        if (
          build.id === releasedBuild?.id ||
          semver.gt(build.appVersion, releasedBuild?.appVersion || "0.0.0")
        ) {
          eligibility.versionState = EligibilityState.eligible;
        } else {
          eligibility.versionState = EligibilityState.ineligible;
        }
      }
    } catch (error) {
      eligibility.versionState = EligibilityState.ineligible;
      console.error(
        "Error determining upgrade eligibility, probably a buggy semver",
        error
      );
    }

    return eligibility;
  } catch (error) {
    console.error(
      "Error determining upgrade eligibility, probably a buggy semver",
      error
    );
    return {
      releaseState: EligibilityState.ineligible,
      versionState: EligibilityState.ineligible,
    };
  }
}

function isDowngradable(releasedBuild?: Build): boolean {
  try {
    return (
      releasedBuild?.todesktopRuntimeVersionUsed &&
      semver.gte(
        releasedBuild.todesktopRuntimeVersionUsed,
        MIN_RUNTIME_VERSION_FOR_DOWNGRADE,
        { loose: true }
      )
    );
  } catch (error) {
    console.error(
      "Error determining downgrade eligibility, probably a buggy semver",
      error
    );
    return false;
  }
}

export const getReleaseEligibilityMessages = (
  releaseEligibility: ReturnType<typeof determineReleaseEligibility>,
  build: Build,
  releasedBuild?: Build
) => {
  const { isDowngrade, isEqual } = releaseEligibility;
  const releaseVersion = releasedBuild ? `(v${releasedBuild.appVersion})` : "";

  const buildStateSuccess = `Build v${build.appVersion} successful`;
  const buildStateProgress = "Build ongoing...";
  const buildStateError = `Build has not been successful`;

  const releaseStateSuccess = "This build is releasable";
  const releaseStateProgress = "Validating release status...";
  const releaseStateError = "This build is not releasable";

  const codeSignStateSuccess = "Code-signing certificates are valid";
  const codeSignStateProgress = "Validating code signing certificates...";
  const codeSignStateError = "Some platforms were not code-signed";

  const versionStateSuccess = isDowngrade
    ? `Version can be downgraded below latest release ${releaseVersion}`
    : isEqual
    ? `Version is the same as the latest release ${releaseVersion}`
    : `Version is greater than the latest release ${releaseVersion}`;
  const versionStateProgress = "Determining version eligibility...";
  const versionStateError = isDowngrade
    ? `Version cannot be downgraded below latest release ${releaseVersion}`
    : isEqual
    ? `Version is the same as the latest release ${releaseVersion}`
    : `Version is less than the latest release ${releaseVersion}`;

  const smokeTestStateSuccess = "Smoke test successful";
  const smokeTestStateProgress = "Smoke test ongoing...";
  let smokeTestStateError = "Smoke tests unsuccessful";
  if (!build.smokeTest) {
    smokeTestStateError = "Smoke tests have not yet run";
  } else if (build.smokeTest.isCanceled) {
    smokeTestStateError = "Smoke tests were cancelled";
  } else if (smokeTestHasError(build)) {
    smokeTestStateError = "Smoke tests failed for this build";
  }

  return {
    buildStateSuccess,
    buildStateProgress,
    buildStateError,
    releaseStateSuccess,
    releaseStateProgress,
    releaseStateError,
    codeSignStateSuccess,
    codeSignStateProgress,
    codeSignStateError,
    versionStateSuccess,
    versionStateProgress,
    versionStateError,
    smokeTestStateSuccess,
    smokeTestStateProgress,
    smokeTestStateError,
  };
};
