import * as yup from "yup";

const firstName = yup.string().required().label("First Name");
const lastName = yup.string().required().label("Last Name");
const email = yup.string().email().required().label("Email");
const password = yup
  .string()
  .required("Password is required")
  .min(8)
  .label("Password");
const passwordConfirmation = yup
  .string()
  .oneOf([yup.ref("password"), null], "Passwords must match")
  .label("Password Confirmation")
  .required();

const fullName = yup.string().required().label("Full Name");

export const upgradeAccountValidation = yup.object().shape({
  firstName,
  lastName,
  email,
  password,
  passwordConfirmation,
});

export const certificateValidation = yup.object().shape({
  password: yup.string().label("Certificate Password"),
  pfxBase64: yup.string().required().label("Certificate File"),
});

export const macNotarizationApiKeyValidation = yup.object().shape({
  apiKeyBase64: yup.string().required().label("Apple Api Key"),
  appleApiKeyId: yup.string().required().label("Apple Api Key ID"),
  appleApiIssuer: yup.string().required().label("Apple Api Issuer"),
});

export const macNotarizationApiKeyEditValidation = yup.object().shape({
  apiKeyBase64: yup.string().label("Apple Api Key"),
  appleApiKeyId: yup.string().required().label("Apple Api Key ID"),
  appleApiIssuer: yup.string().required().label("Apple Api Issuer"),
});

export const macNotarizationPasswordValidation = yup.object().shape({
  appleId: yup.string().required().label("Apple ID"),
  appleIdPassword: yup.string().required().label("App Specific Password"),
  teamId: yup.string().required().label("Team ID"),
});

export const macNotarizationPasswordEditValidation = yup.object().shape({
  appleId: yup.string().required().label("Apple ID"),
  appleIdPassword: yup.string().label("App Specific Password"),
  teamId: yup.string().label("Team ID"),
});

export const windowsFileCertValidation = yup.object().shape({
  certPasswordWin: yup.string().label("Certificate Password"),
  certFile: yup.string().required().label("Certificate File"),
});

export const linuxPgpValidation = yup.object().shape({
  password: yup.string().label("PGP Private Key Password"),
  ascString: yup.string().required().label("PGP Private Key"),
});

export const payValidation = yup.object().shape({
  billingName: fullName,
});

const customDomain = yup
  .string()
  .label("Custom Domain")
  .matches(
    /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/,
    "Must set a valid custom domain (e.g. download.domain.com)"
  )
  .required();

export const customDomainValidation = yup.object().shape({
  customDomain,
});

export const environmentKeyValueValidation = ({ key, value }) =>
  yup.object().shape({
    [key]: yup.string().label("Environment variable key").required(),
    [value]: yup.string().label("Environment variable value"),
  });

export const pngIconValidation = yup
  .mixed()
  .required()
  .test("fileType", "Only PNGs are supported", (value) => {
    return typeof value === "string" ? value.includes(".png") : false;
  });

export const ipAddressesValidation = yup
  .string()
  .test(
    "fileType",
    "IP address must be in an ipv4 or ipv6 comma-separated format",
    (value: string) => {
      const ipv4Pattern = /^(\d{1,3}\.){3}\d{1,3}$/;
      const ipv6Pattern = /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/;

      return value
        .split(",")
        .map((address) => address.trim())
        .filter(Boolean)
        .every((ip) => ipv4Pattern.test(ip) || ipv6Pattern.test(ip));
    }
  );

export const appxIdentityNameValidation = yup.object().shape({
  identityName: yup
    .string()
    .label("Identity Name")
    .matches(
      /^[-.A-Za-z0-9]+$/,
      "Identity name must not contain spaces or special symbols"
    )
    .min(3)
    .max(50)
    .required(),
});

export const appxDisplayNameValidation = yup.object().shape({
  displayName: yup.string().label("Display Name").required(),
});

export const appxPublisherDisplayNameValidation = yup.object().shape({
  publisherDisplayName: yup.string().label("Publisher Display Name").required(),
});

export const appxPublisherValidation = yup.object().shape({
  publisher: yup
    .string()
    .label("Publisher")
    .test(
      "publisher",
      `Publisher must obey format of (CN|L|O|OU|E|C|S|STREET|T|G|I|SN|DC|SERIALNUMBER|(OID\.(0|[1-9][0-9]*)(\.(0|[1-9][0-9]*))+))=(([^,+="<>#;])+|".*")(, ((CN|L|O|OU|E|C|S|STREET|T|G|I|SN|DC|SERIALNUMBER|(OID\.(0|[1-9][0-9]*)(\.(0|[1-9][0-9]*))+))=(([^,+="<>#;])+|".*")))*`,
      (value) => {
        return value
          ? /^(CN|L|O|OU|E|C|S|STREET|T|G|I|SN|DC|SERIALNUMBER|(OID\.(0|[1-9][0-9]*)(\.(0|[1-9][0-9]*))+))=(([^,+="<>#;])+|".*")(, ((CN|L|O|OU|E|C|S|STREET|T|G|I|SN|DC|SERIALNUMBER|(OID\.(0|[1-9][0-9]*)(\.(0|[1-9][0-9]*))+))=(([^,+="<>#;])+|".*")))*$/.test(
              value
            )
          : true;
      }
    )
    .nullable(true)
    .max(8192),
});
