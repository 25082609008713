// modules
import React, { useState } from "react";
import copy from "copy-to-clipboard";
import {
  PlusOutlined,
  CopyOutlined,
  SettingOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button, Modal, Input, message, Dropdown } from "antd";
import confirm from "antd/lib/modal/confirm";

// components
import { StyledAntdCard } from "../../../components/atoms/StyledAntdCard";
import { LabelledItem } from "../../../components/atoms/LabelledItem";
import { useStore, selectedApp } from "../../../store";
import { Text } from "../../../components/atoms/Text";
import { callFirebaseFunction } from "../../../~reusables/firebase/functions";
import { Flex } from "../../../components/atoms/Primitives";

interface AppWebhooksProps {
  id: string;
  webhooks:
    | {
        release?: string;
      }
    | undefined;
  webhookHMACKey?: string;
}

const AppWebhooks: React.FC<AppWebhooksProps> = ({
  id,
  webhooks,
  webhookHMACKey,
}) => {
  const [
    isReleaseWebhookModalVisible,
    setIsReleaseWebhookModalVisible,
  ] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleAddWebhook = async () => {
    if (!webhookUrl) {
      message.error("Please enter a webhook URL");
      return;
    }

    if (!webhookUrl.startsWith("https://")) {
      message.error(
        "Please enter a valid webhook URL that begins with https://"
      );
      return;
    }

    setIsLoading(true);
    try {
      const createWebhookFn = callFirebaseFunction("createWebhook");
      await createWebhookFn({
        appId: id,
        url: webhookUrl,
        type: "release",
      });
      message.success("Webhook added successfully");
      setIsReleaseWebhookModalVisible(false);
      setWebhookUrl("");
    } catch (error) {
      message.error("Failed to add webhook: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <StyledAntdCard
        title="App Webhooks"
        extra={
          <Flex css={{ flexDirection: "row", gap: "8px" }}>
            <Dropdown
              disabled={false}
              menu={{
                onClick: (e) => {
                  if (e.key === "release") {
                    if (webhooks?.release) {
                      setWebhookUrl(webhooks.release);
                    }
                    setIsReleaseWebhookModalVisible(true);
                  }
                },
                items: [
                  {
                    label: `${
                      webhooks?.release ? "Edit" : "Add"
                    } Release Webhook`,
                    key: "release",
                  },
                ],
              }}
            >
              <Button>
                <PlusOutlined />
              </Button>
            </Dropdown>
            {webhooks?.release ? (
              <Dropdown
                menu={{
                  onClick: async (e) => {
                    if (e.key === "delete-release") {
                      confirm({
                        title:
                          "Are you sure you want to delete the release webhook?",
                        onOk: async () => {
                          await callFirebaseFunction("deleteWebhook")({
                            appId: id,
                            type: "release",
                          });
                          message.success(
                            "Release webhook deleted successfully"
                          );
                        },
                      });
                    }
                  },
                  items: [
                    {
                      label: "Delete Release Webhook",
                      key: "delete-release",
                    },
                  ],
                }}
              >
                <Button>
                  <SettingOutlined /> <DownOutlined />
                </Button>
              </Dropdown>
            ) : null}
          </Flex>
        }
      >
        {webhookHMACKey ? (
          <LabelledItem label="Webhook Secret:">
            <Text>
              {webhookHMACKey}
              <CopyOutlined
                css={{ marginLeft: "8px" }}
                disabled={!webhookHMACKey}
                onClick={() => {
                  copy(webhookHMACKey);
                  message.success(
                    "Release secret (HMAC) has been copied to your clipboard."
                  );
                }}
              />
            </Text>
          </LabelledItem>
        ) : null}
        {webhooks?.release ? (
          <LabelledItem label="Release webhook URL:">
            <Text>{webhooks.release}</Text>
          </LabelledItem>
        ) : (
          <Text>No webhooks configured</Text>
        )}
      </StyledAntdCard>

      <Modal
        title="Add Release Webhook"
        open={isReleaseWebhookModalVisible}
        onOk={handleAddWebhook}
        onCancel={() => {
          setIsReleaseWebhookModalVisible(false);
          setWebhookUrl("");
        }}
        confirmLoading={isLoading}
      >
        <p>Enter the URL where release notifications should be sent:</p>
        <Input
          placeholder="https://your-domain.com/webhook"
          value={webhookUrl}
          autoFocus={isReleaseWebhookModalVisible}
          onChange={(e) => setWebhookUrl(e.target.value)}
        />
      </Modal>
    </>
  );
};

export const General: React.FC = () => {
  const app = useStore(selectedApp);
  const { id, appPkgName, name, appModelId } = app;
  const webhooks = app.meta?.webhooks;

  const appInformation = [
    { label: "ToDesktop ID:", value: id },
    { label: "App name:", value: name },
    ...(appPkgName ? [{ label: "Package name:", value: appPkgName }] : []),
    ...(appModelId ? [{ label: "App ID:", value: appModelId }] : []),
  ];

  return (
    <>
      <StyledAntdCard title="App information">
        {appInformation.map(({ label, value }, idx) => (
          <LabelledItem
            key={label}
            label={label}
            altBg={idx % 2 !== 0 ? true : false}
          >
            <Text>{value}</Text>
          </LabelledItem>
        ))}
      </StyledAntdCard>

      {app.appType === "electron" ? (
        <AppWebhooks
          id={id}
          webhooks={webhooks}
          webhookHMACKey={app.meta?.webhookHMACKey}
        />
      ) : null}
    </>
  );
};
