import React, { useState, useEffect } from "react";
import type firebase from "firebase/app";
import {
  useStore,
  $windowsEVOnboardingProvider,
  selectedApp,
  $tempHSMCertName,
} from "../../../store";
import { Space } from "../../atoms/Space";
import { Alert, Select, Button, message, Tooltip, Input } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { Text } from "../../atoms/Text";
import { Anchor, Container } from "../../atoms/Primitives";
import { CertPlatform, IHSMCertificate } from "../../../~reusables/types";
import {
  WindowsHSMCertType,
  WindowsEVOnboardingSteps,
  WindowsEVOnboardingProvider,
  IApp2,
  IApp,
} from "@todesktop/shared";
import certPlaceholder from "../../../~reusables/images/certificate.png";
import {
  updateWindowsEVOnboardingProvider,
  updateFirestoreApp,
  isFileChangeError,
  handleFileChange,
  isFileUploadError,
  toText,
  updateWindowsCert,
} from "../../../~reusables/actions";
import { TooltipText } from "../../atoms/TextUtils";
import { callFirebaseFunction } from "../../../~reusables/firebase";
import { track } from "../../../~reusables/util/analytics";
import { UploadChangeParam } from "antd/lib/upload";
import { storageRef } from "../../../~reusables/firebase/firestore";
import { UploadField } from "../../atoms/UploadField";
import { CustomCheckbox } from "../../edit/ChecklistItems";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useWindowSize } from "../../../~reusables/hooks/useWindowSize";
import { useTheme } from "../../../~reusables/contexts/ThemeContext";
import { handleChatClick } from "../../build/ChoosePackage";
import { copyToClipboard } from "../../../~reusables/util";
import { isTDBuilderApp } from "../../../~reusables/util/schemaChecks";

export const ChooseProvider: React.FC = () => {
  const { provider } = useStore($windowsEVOnboardingProvider);

  return (
    <Space size="md" direction="column" align="flex-start">
      <Alert
        showIcon
        type="info"
        message={
          <Text variant="body3">
            Setting up an EV certificate is more expensive and involved, but
            will get you immediate “reputation”. This means that your desktop
            app users will never be warned that your software is untrusted.
            <br />
            <br />
            We recommend that you use{" "}
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.globalsign.com/en"
            >
              GlobalSign
            </Anchor>{" "}
            as your Certificate Authority Provider.
          </Text>
        }
      />
      <Select
        value={provider}
        defaultValue={WindowsEVOnboardingProvider.globalsign}
        onChange={updateWindowsEVOnboardingProvider}
      >
        <Select.Option value={WindowsEVOnboardingProvider.globalsign}>
          GlobalSign
        </Select.Option>
        <Select.Option value={WindowsEVOnboardingProvider.other}>
          Other
        </Select.Option>
      </Select>
      {ProviderSteps.renderComponent(
        WindowsEVOnboardingSteps.hasChosenProvider,
        provider
      )}
    </Space>
  );
};

export const OrderCert: React.FC = () => {
  return (
    <Space width="100%" size="md" direction="column" align="flex-start">
      <Text variant="body3">
        Next, you will need to order the certificate online. Make that you order
        the code signing for HSM certificate. If this option is not visible, you
        will need to contact your sales rep. It needs to be enabled manually
        from their side.
      </Text>
      <Alert
        showIcon
        type="info"
        message={
          <Text variant="body3">
            Learn more about the process for ordering a cert{" "}
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.globalsign.com/code-signing/ordering-ev-code-signing-certificate-hsm-based"
            >
              here
            </Anchor>
            .
          </Text>
        }
      />
      <Text variant="body3">
        Take note of the <b>temporary password</b> you’re given as you will need
        it in a later step.
      </Text>
    </Space>
  );
};

export const GetVerified: React.FC = () => {
  return (
    <Space width="100%" size="md" direction="column" align="flex-start">
      <Text variant="body3">
        You will now need to go through the vetting process with GlobalSign.
        This usually takes a couple of days, but you can expect the following:
      </Text>
      <ol>
        <li>
          They will try to verify your{" "}
          <TooltipText
            text="company details"
            toolTip={
              <ul>
                <li>
                  Your company/organisation's full registered name and address.
                </li>
                <li>
                  Your company/organisation's registration number. For Delaware
                  corps this is the file number, it’s on the certificate of
                  incorporation.
                </li>
                <li>
                  The registration authority with which your
                  company/organisation is registered.
                </li>
              </ul>
            }
          />
          .
        </li>
        <li>
          Next, They will ask you for a selfie with a{" "}
          <TooltipText
            text="photo ID"
            toolTip="You can provide a driving licence or passport. They ask for a good quality photo where they can read all the details on the ID."
          />
          .
        </li>
        <li>
          Next, They will ask for your signature on the Certificate Request Form
          and the Subscriber Agreement Acceptance Form. You will need the
          following HSM details:
          <ul>
            <li>Manufacturer: Gemalto</li>
            <li>Model: SafeNet Network HSM 7 Model A790</li>
          </ul>
        </li>
        <li>
          They will then call you on a{" "}
          <TooltipText
            text="registered business number"
            toolTip={
              <ul>
                <li>
                  Make sure that the number is correct on Dun and Bradstreet
                  because they won’t accept a replacement phone number.
                </li>
                <li>
                  You don’t need to able the answer the phone call they can
                  leave a voicemail with the password
                </li>
              </ul>
            }
          />{" "}
          and leave a password.
        </li>
        <li>
          Finally, you will email them the password that they told you in step
          4.
        </li>
      </ol>
    </Space>
  );
};

const beginAddEVCodeSigningCert = callFirebaseFunction(
  "beginAddEVCodeSigningCert"
);

const getPendingHSMCertCSR = callFirebaseFunction("getPendingHSMCertCSR");

export const GenerateCert: React.FC = () => {
  const { provider } = useStore($windowsEVOnboardingProvider);
  const [isGenerating, setGenerating] = useState(false);
  const [wrappedCsr, setWrappedCsr] = useState("");
  const [isCSRLoading, setCsrLoading] = useState(true);
  const { colors } = useTheme();
  const tempHSMCertName = useStore($tempHSMCertName);
  const app = useStore(selectedApp) as IApp | IApp2;

  let name = "";
  let id = "";
  if ("desktopApp" in app) {
    name = app.desktopApp.name;
    id = app.desktopApp.id || app.id;
  } else if ("name" in app) {
    name = app.name;
    id = app.id;
  }

  useEffect(() => {
    if (tempHSMCertName) {
      setCsrLoading(true);
      getPendingHSMCertCSR({ hsmCertName: tempHSMCertName })
        .then((res) => setWrappedCsr(res.data.wrappedCsr))
        .catch((err) => message.error(err.message))
        .finally(() => setCsrLoading(false));
    } else {
      setCsrLoading(false);
    }
  }, [tempHSMCertName]);

  const isDisabled = isCSRLoading || !!wrappedCsr;

  return (
    <Space width="100%" size="md" direction="column" align="flex-start">
      {ProviderSteps.renderComponent(
        WindowsEVOnboardingSteps.hasGeneratedCert,
        provider
      )}
      <Tooltip
        title={
          isDisabled
            ? "If you need to generate a new CSR, please contact us."
            : ""
        }
      >
        <Button
          loading={isGenerating}
          disabled={isDisabled}
          type="primary"
          onClick={async () => {
            setGenerating(true);
            const { data } = await beginAddEVCodeSigningCert({
              appName: name,
              appId: id,
            });
            const { hsmCertName: tempHSMCertName, wrappedCsr } = data;
            updateFirestoreApp({
              meta: { windowsEVOnboarding: { tempHSMCertName } },
            });
            setWrappedCsr(wrappedCsr);
            setGenerating(false);
          }}
        >
          Generate CSR
        </Button>
      </Tooltip>
      <Container position="relative">
        <Input.TextArea
          rows={4}
          spellCheck={false}
          value={wrappedCsr}
          disabled={isGenerating}
        />
        {wrappedCsr && (
          <CopyOutlined
            onClick={copyToClipboard("CSR", wrappedCsr)}
            style={{
              color: colors.primary,
              position: "absolute",
              right: "16px",
              opacity: "0.8",
              top: "16px",
              cursor: "pointer",
            }}
          />
        )}
      </Container>
    </Space>
  );
};

const mergeExistingCert = callFirebaseFunction("mergeExistingCert");

export const UploadCert: React.FC<{
  setHSMCertDetails: React.Dispatch<React.SetStateAction<IHSMCertificate>>;
}> = ({ setHSMCertDetails }) => {
  let firebaseUploadTask: firebase.storage.UploadTask;
  const [certName, setCertName] = useState("");
  const { provider } = useStore($windowsEVOnboardingProvider);
  const tempHSMCertName = useStore($tempHSMCertName);
  const app = useStore(selectedApp);
  const [isUploading, setUploading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  let id = "";
  if (isTDBuilderApp(app)) {
    id = app.desktopApp.id || app.id;
  } else {
    id = app.id;
  }

  const handleSubmit = async (crtBase64: string) => {
    if (!tempHSMCertName) {
      message.error("Please generate a CSR from the previous step");
      return;
    }

    try {
      track({
        event: "Update Certificate",
        properties: {
          action: "save",
          platform: CertPlatform.Windows,
          provider,
        },
      });
      const { data } = await mergeExistingCert({
        hsmCertName: tempHSMCertName,
        crtBase64,
        appId: id,
      });

      await updateWindowsCert(app, "windows", {
        certType: "hsm",
        hsmCertType: WindowsHSMCertType.ev,
        hsmCertName: tempHSMCertName,
      });
      setHSMCertDetails(data);

      message.success("Windows Certificate saved");
    } catch (err) {
      message.error(err.message);
      track({
        event: "Update Certificate",
        properties: {
          action: "error",
          platform: CertPlatform.Windows,
          provider,
        },
      });
    }
  };

  const certChange = ({ file, event }: UploadChangeParam) => {
    setCertName("");
    if (isFileChangeError(file)) {
      return;
    }

    if (event) {
      setUploading(true);
      setUploadPercent(event.percent);
    }

    if (file.status === "done") {
      toText(file.originFileObj)
        .then((base64: string) => {
          handleSubmit(base64, file.name);
        })
        .catch((err) => message.error(err.message, 3))
        .finally(() => {
          setUploading(false);
          setUploadPercent(0);
        });
    }
  };

  const certUpload = ({ file, onSuccess, onProgress, onError }: any) => {
    try {
      const imageRef = storageRef.child(`${id}/${file.name}`);
      firebaseUploadTask = imageRef.put(file);
      firebaseUploadTask.on(
        "state_changed",
        (snapshot) => {
          setUploading(true);
          onProgress(
            {
              percent: Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              ).toFixed(2),
            },
            file
          );
        },
        (error) => isFileUploadError(error, onError),
        async () => {
          const downloadURL = await imageRef.getDownloadURL();
          onSuccess({
            url: downloadURL,
          });
        }
      );
    } catch (error) {
      isFileUploadError(error, onError);
    }
  };

  return (
    <Space width="100%" size="md" direction="column" align="flex-start">
      <Text variant="body3">
        Once the cert has been generated and downloaded, you will need to send
        us the certificate file so that we can upload the cert to our HSM and
        link your ToDesktop CLI account with the new cert.
      </Text>
      <UploadField
        firebaseUploadTask={firebaseUploadTask}
        onChange={certChange}
        customRequest={handleFileChange(certUpload, firebaseUploadTask)}
        isUploading={isUploading}
        uploadPercent={uploadPercent}
        icon={certName ? certPlaceholder : null}
        label="Certificate file"
        uploadedName={certName || ""}
        accept=".crt,.cer"
      />
    </Space>
  );
};

export const EVStepsCheckbox: React.FC<{
  onChange: (e: CheckboxChangeEvent) => void;
  checked: boolean;
  isActive: boolean;
}> = ({ onChange, checked, isActive }) => {
  const { breakpoints } = useTheme();
  const windowSize = useWindowSize();
  const isTablet = windowSize.width < parseInt(breakpoints[1]);

  return (
    <CustomCheckbox
      borderColor={checked ? "#006df6" : isActive ? "#1A202C" : "auto"}
      scale={isTablet ? 1.4 : 1.7}
      onChange={onChange}
      checked={checked}
    />
  );
};
class ProviderSteps {
  static renderStep(step: WindowsEVOnboardingSteps) {
    switch (step) {
      case WindowsEVOnboardingSteps.hasChosenProvider:
        return this.ChooseProvider;
      case WindowsEVOnboardingSteps.hasGeneratedCert:
        return this.GenerateCert;
    }
  }

  static renderComponent = (
    step: WindowsEVOnboardingSteps,
    provider: WindowsEVOnboardingProvider
  ) => {
    switch (provider) {
      case WindowsEVOnboardingProvider.other:
        return Other.renderStep(step);
      case WindowsEVOnboardingProvider.globalsign:
        return GlobalSign.renderStep(step);
      default:
        return null;
    }
  };

  static GenerateCert: JSX.Element;
  static ChooseProvider: JSX.Element;
}

class GlobalSign extends ProviderSteps {
  static ChooseProvider = (
    <Text variant="body3">
      To get started, please{" "}
      <Anchor
        onClick={() =>
          window.open("https://shop.globalsign.com/en/code-signing", "_blank")
        }
      >
        purchase a HSM certificate from GlobalSign
      </Anchor>
      . It is very important that you choose a HSM implementation and not a USB
      Token implementation. We generally recommend choosing the EV certificate
      option also because it will give you immediate reputation.
    </Text>
  );

  static GenerateCert = (
    <>
      <Text variant="body3">
        After you have been verified, GlobalSign will send you a certificate
        generation link.
        <br />
        <br />
        For this step, you will need the <b>temporary password</b> that was
        created earlier and a CSR which you can generate here. Please note that
        you can only generate the CSR once.
      </Text>
      <Space direction="column" />
      <Alert
        showIcon
        type="info"
        message={
          <Text variant="body3">
            Once you have the password and CSR, you will need to download and
            install the certificate via GlobalSign’s interface. You can learn
            how to do this by following{" "}
            <Anchor
              target="_blank"
              rel="noopener noreferrer"
              href="https://support.globalsign.com/code-signing/download-and-install-code-signing-certificate-hsm-based"
            >
              these instructions
            </Anchor>
            .
          </Text>
        }
      />
    </>
  );
}

class Other extends ProviderSteps {
  static ChooseProvider = (
    <Text variant="body3">
      Follow the instructions of your Certificate Provider for ordering your HSM
      certificate and getting verified.{" "}
      <Anchor
        onClick={(e) =>
          handleChatClick(
            e,
            "Hi, could you help me set up an alternative Certificate Provider"
          )
        }
      >
        Contact us
      </Anchor>{" "}
      if you need assistance.
    </Text>
  );

  static GenerateCert = (
    <Text variant="body3">
      For generating a certificate with your provider, you will need a CSR which
      you can generate here. Please note that you can only generate the CSR
      once.
    </Text>
  );
}
